import { useState } from "react";
import Button from "../components/button/Button";
import axiosInstance from "../utils/axios-instance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { ParseErrors } from "../utils/parse-errors";
import { SubscriptionType } from "../types/subscription-types";

interface ICreateManualSubscriptionModel {
  agencyId: number;
  toggleSubscriptionModel: () => void;
  isSubscription: SubscriptionType;
}

const CreateManualSubscriptionModel = ({
  agencyId,
  toggleSubscriptionModel,
  isSubscription,
}: ICreateManualSubscriptionModel) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<Record<string, any>>({
    plan: "monthly",
  });

  // ------------------------- [ CREATE MANUAL SUBSCRIPTION] ----------------------------------
  const CreateSubscription = async () => {
    setLoading(true);
    try {
      await axiosInstance.post(
        `/payment/create-manual-subscription/${agencyId}`,
        {
          ...values,
        }
      );
      toast.success("Subscription Created");
      toggleSubscriptionModel();
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(ParseErrors(error));
      toggleSubscriptionModel();
      setLoading(false);
    }
  };

  // ------------------------- [ CREATE MANUAL SUBSCRIPTION] ----------------------------------
  const UpdateSubscription = async () => {
    setLoading(true);
    try {
      await axiosInstance.patch(
        `/payment/manual-subscription/${isSubscription?.id}`,
        {
          ...values,
        }
      );
      toast.success("Subscription Updated");
      toggleSubscriptionModel();
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(ParseErrors(error));
      toggleSubscriptionModel();
      setLoading(false);
    }
  };

  // ------------------------- [ CREATE MANUAL SUBSCRIPTION] ----------------------------------
  const CancelSubscription = async () => {
    setLoading(true);
    try {
      await axiosInstance.put(
        `/payment/super-admin/stripe/cancel-subscription/${agencyId}`,
        {
          ...values,
        }
      );
      toast.success("Subscription Cancelled");
      toggleSubscriptionModel();
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(ParseErrors(error));
      toggleSubscriptionModel();
      setLoading(false);
    }
  };

  // ---------------------------------- [ON CHANGE VALUES] --------------------------------
  const HandleOnChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className="w-full h-full fixed top-0 bg-[rgba(0,0,0,0.5)] left-0 z-50 flex items-center justify-center">
      <div className="p-6  rounded-md bg-white shadow-sm min-w-[450px]">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-[500] text-black">
            {isSubscription?.id ? "Update" : "Create"} Subscription
          </h2>

          <FontAwesomeIcon
            onClick={toggleSubscriptionModel}
            icon={faXmark}
            className="text-black text-2xl cursor-pointer"
          />
        </div>

        <div className="w-full mt-6 space-y-4">
          <div>
            <label className="text-black text-md font-[500]">Total Users</label>
            <input
              name="totalUsers"
              defaultValue={values?.totalUsers || isSubscription?.totalUsers}
              onChange={HandleOnChange}
              className="mt-1 w-full h-[50px] border-gray-200 border-[1px] rounded-sm px-4"
            />
          </div>
          <div>
            <label className="text-black text-md font-[500]">Status</label>
            <select
              name="status"
              onChange={HandleOnChange}
              defaultValue={values?.status || isSubscription?.status}
              className="mt-1 w-full h-[50px] border-gray-200 border-[1px] rounded-sm px-4"
            >
              <option value="paid">Paid</option>
              <option value="unpaid">Unpaid</option>
            </select>
          </div>
          <div>
            <label className="text-black text-md font-[500]">
              Plan Duration
            </label>
            <select
              name="plan"
              onChange={HandleOnChange}
              defaultValue={values?.plan || isSubscription?.plan}
              className="mt-1 w-full h-[50px] border-gray-200 border-[1px] rounded-sm px-4"
            >
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>

          <Button
            isLoading={loading}
            disabled={loading}
            onClick={
              isSubscription?.id ? UpdateSubscription : CreateSubscription
            }
            className="!mt-6"
          >
            Submit
          </Button>

          {isSubscription?.id ? (
            <Button
              className="bg-red-500"
              isLoading={loading}
              disabled={loading}
              onClick={CancelSubscription}
            >
              Cancel Subsription
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CreateManualSubscriptionModel;
