import React from "react";
import StyledH1Heading from "../../common/components/styledH1Heading/StyledH1Heading.tsx";
import StyledLgText from "../../common/components/styledLgText/StyledLgText.tsx";
import StyledH2Heading from "../../common/components/styledH2Heading/StyledH2Heading.tsx";
import CustomHeadig from "../../common/components/customHeading/CustonHeding.tsx";
import Button from "../button/Button.tsx";
import { content } from "../../data.tsx";
import { useNavigate } from "react-router-dom";
import UseAuth from "../../utils/use-auth.ts";

const Home_discover = () => {
  const { user } = UseAuth();
  const navigate = useNavigate();

  return (
    <div className="w-[100%] pt-[130px] pb-10 flex flex-col items-center justify-center ">
      <div className="lg:flex-row flex gap-10  flex-col  w-[95%] justify-center lg:justify-around items-center pb-20">
        <div className="w-[95%] text-center lg:w-[40%] gap-3 lg:text-start items-center justify-center lg:items-start lg:justify-start flex flex-col">
          <StyledH1Heading
            content={"Discover the Power of "}
            fontColor={"text-black"}
            customColor={"text-blue"}
            diffText={"Coach AI"}
            className="!leading-[5rem]"
          />
          <StyledLgText
            children={
              "Discover the convenience and effectiveness of our state-of-the-art platform, designed with the precision of business coaching in mind. Enhance your professional growth and development with tools that match the quality of traditional coaching methods."
            }
            fontColor={"text-lightBlack "}
          />
          <div className="lg:w-1/3">
            <Button
              children={user?.id ? "Start Chat" : "Sign up"}
              className="mt-4 !py-[14px] px-[25px]"
              onClick={() => navigate(user?.id ? "/chats" :"/login")}
            />
          </div>
        </div>
        <div className=" w-[70%] lg:w-[50%] items-center justify-center rounded-3xl overflow-hidden">
          <img src="/assets/images/homesection1.png" alt="male image" />
        </div>
      </div>
      <div className="py-20 flex text-center flex-col items-center justify-center">
        <StyledH2Heading
          regularContent={
            "Trusted by industry-leading companies around the globe"
          }
          regularFontColor={"text-black"}
        />
        <div className="flex lg:flex-row flex-col gap-8 w-full pt-8 justify-between items-center ">
          {content.leadingCompanies?.map((item, index) => (
            <div
              key={index}
              className="flex gap-10 justify-center items-center"
            >
              <img src={item.imagUrl} alt="companies" className="w-[200px]" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home_discover;
