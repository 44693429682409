import RemoveHtmlTags from "./remove-html-tags";

function ExporttoText(userInfo: any) {
  const fileData = RemoveHtmlTags(userInfo.join("\n\n"));
  const blob = new Blob([fileData], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = "user-info.txt";
  link.href = url;
  link.click();
}

export default ExporttoText;
