import { useEffect, useState } from "react"
import gptModelArray from "../../utils/gpt-models"
import Button from "../button/Button"
import { IGptModel } from "../../types/gpt-interface"
import axiosInstance from "../../utils/axios-instance"
import { toast } from "react-toastify"
import { ParseErrors } from "../../utils/parse-errors"


const GptSettings = () => {
    const [gptData, setGptData] = useState<IGptModel>({})
    const [values, setValues] = useState<Record<string, any>>()
    const [loading, setLoading] = useState<boolean>(false)


    // -------------------------------- [ HANDLE ON CHANGE ] --------------------------------
    const HandleOnChange = (e) => {
        setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }


    // -------------------------------- [ UPDATE GPT DATA ] --------------------------------
    const UpdateGptData = async () => {
        setLoading(true)
        try {
            await axiosInstance.post('/gpt/create', values)
            toast.success("GPT updated successfully")
            setLoading(false)
        } catch (err) {
            console.log(err)
            toast.error(ParseErrors(err))
            setLoading(false)
        }
    }

    // -------------------------------- [ GET DETAILS ] --------------------------------
    useEffect(() => {
        const getGptModel = async () => {
            try {
                const res = await axiosInstance.get('/gpt')
                setGptData(res.data.data)
            } catch (err) {
                console.log(err)
            }
        }

        getGptModel()
    }, [])


    return (
        <div className="w-full pb-10">

            <div className="w-[50%] mx-auto">
                <h2 className="text-3xl text-blue font-bold">AI Settings</h2>

                <div className="w-full mt-4 space-y-6">

                    <div className="w-full">
                        <label className="text-md text-gray-500">Api Key</label>
                        <input type="text" onChange={HandleOnChange} name="apiKey" defaultValue={gptData.apiKey} className="w-full h-[50px] border-[1px] border-gray-600 rounded-md mt-2 px-4" />
                    </div>
                    <div className="w-full">
                        <label className="text-md text-gray-500">Gpt Model</label>
                        <select name="model" onChange={HandleOnChange} className="w-full h-[50px] border-[1px] border-gray-600 rounded-md mt-2 px-4">

                            {gptModelArray.map((item) => {
                                return <option value={item} selected={gptData.model === item} >{item}</option>
                            })}
                        </select>
                    </div>
                    <div className="w-full">
                        <label className="text-md text-gray-500">Max Tokens ( Number )</label>
                        <input type="number" onChange={HandleOnChange} defaultValue={gptData.maxTokens} name="maxTokens" className="w-full h-[50px] border-[1px] border-gray-600 rounded-md mt-2 px-4" />
                    </div>
                    <div className="w-full">
                        <label className="text-md text-gray-500">Prompt</label>
                        <textarea name="prompt" onChange={HandleOnChange} defaultValue={gptData.prompt} className="w-full h-[140px] border-[1px] border-gray-600 rounded-md mt-2 px-4 py-2" />
                    </div>
                    <Button disabled={!values || loading} isLoading={loading} onClick={UpdateGptData}>Submit</Button>
                </div>
            </div>

        </div>
    )
}

export default GptSettings
