

const ParseErrors = (error: any) => {

  if (
    (!error?.response?.data?.user?.subscription?.id ||
      error?.response?.data?.user?.subscription?.type !== "paid") &&
    error?.response.status === 403
  ) {
    window.location.href = `/pricing?subtype=${error?.response?.data?.user?.subscription?.status}&id=${error?.response?.data?.user?.subscription?.id}`;
  }

  if (
    error?.response?.data?.isLoggedIn === false &&
    error?.response.status === 419
  ) {
    window.location.pathname = "/";
  }

  if (error?.response?.data?.error?.errors?.length) {
    return error?.response?.data?.error?.errors[0]?.message;
  }

  if (error?.response?.data?.message) {
    return error?.response?.data?.message;
  }

  if (error?.response?.data?.errors?.length) {
    return error?.response?.data?.errors[0]?.msg;
  }

  return "Unknown error";
};

export { ParseErrors };
