import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import StyledXlText from "../common/components/styledXlText/StyledXlText.tsx";
import { content } from "../data.tsx";
import Button from "../components/button/Button.tsx";
import UseAuth from "../utils/use-auth.ts";
import UseChat from "../utils/use-chat.ts";

const MobileHeader = ({ activeSection }) => {
  const [navShow, setNavShow] = useState(false);
  const { setCurrentConversation } = UseChat();
  const { user, HandleUserLogOut } = UseAuth();

  const onToggleNav = () => {
    setNavShow((status) => {
      if (status) {
        document.body.style.overflow = "auto";
      } else {
        // Prevent scrolling
        document.body.style.overflow = "hidden";
      }
      return !status;
    });
  };

  return (
    <div className="lg:hidden">
      <button
        type="button"
        className="mt-2 text-xl leading-none bg-transparent rounded-full outline-none cursor-pointer focus:outline-none"
        aria-label="Toggle Menu"
        onClick={onToggleNav}
      >
        <span>|||</span>
      </button>
      <div
        className={`fixed top-0 left-0 z-10 h-full w-[100vw] transform bg-white ${
          navShow ? "translate-x-[0%]" : "-translate-x-full"
        }`}
        style={{ transition: "all 0.5s cubic-bezier(.68,-0.55,.27,1.55)" }}
      >
        <div className="flex justify-end">
          <button
            type="button"
            className="z-10 w-4 h-4 mt-4 mr-4 rounded"
            aria-label="Toggle Menu"
            onClick={onToggleNav}
          >
            <span>X</span>
          </button>
        </div>
        <nav className="fixed top-0 left-0 h-full w-[100vw]">
          <div className="flex flex-col justify-around h-full gap-4">
            <div className="flex justify-center w-full mt-6">
              <Link to="/" className="flex justify-center w-[60%]">
                <img
                  className="h-full "
                  src="/assets/images/coachai.png"
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="flex flex-col gap-4 text-center">
              {content?.menuItems?.map((item, index) => (
                <Link
                  to={item.url}
                  onClick={onToggleNav}
                  className="uppercase"
                  key={index}
                >
                  <StyledXlText
                    fontColor={`text-lightBlack hover:text-blue ${
                      activeSection === item.head ? "!text-blue" : ""
                    }`}
                  >
                    {item.head}
                  </StyledXlText>
                </Link>
              ))}
            </div>
            <div className="flex justify-center flex-col items-center gap-4">
              {user?.id ? (
                <div className="flex flex-col space-y-4 items-center justify-center">
                  <NavLink
                    to={"/chats"}
                    onClick={() => setCurrentConversation(null)}
                  >
                    <Button
                      children="Start Chat"
                      variant="secondary"
                      disabled={false}
                    />
                  </NavLink>

                  <NavLink to={"/user-setting"}>
                    <Button
                      children="Setting"
                      variant="secondary"
                      disabled={false}
                    />
                  </NavLink>
                  <Button
                    children="Logout"
                    variant="primary"
                    disabled={false}
                    onClick={HandleUserLogOut}
                  />
                  <NavLink to={"/user-setting"}>
                    <div className="h-[45px] w-[45px] rounded-full flex items-center justify-center bg-blue">
                      <h2 className="uppercase text-white font-[500]">
                        {user.name.slice(0, 2)}
                      </h2>
                    </div>
                  </NavLink>
                </div>
              ) : (
                <div className="flex">
                  <NavLink to={"/login"}>
                    <Button children="Login" variant="secondary" />
                  </NavLink>
                  <NavLink to={"/signup"}>
                    <Button children="Signup" />
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default MobileHeader;
