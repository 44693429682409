import { useState } from "react";
import Button from "../button/Button.tsx";
import axiosInstance from "../../utils/axios-instance.ts";
import { toast } from "react-toastify";
import { ParseErrors } from "../../utils/parse-errors.ts";
import { useNavigate } from "react-router-dom";
import UseAuth from "../../utils/use-auth.ts";

const PricingCard = ({
  title,
  subtitle,
  features,
  type,
  isActive,
  ind,
  isMonthly,
}) => {
  const { user } = UseAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const HandleSubscription = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.post(
        "/payment/stripe/create-subscription",
        { type, plan: isMonthly ? "monthly" : "yearly" }
      );
      window.location = res.data.session.url;
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(ParseErrors(error));
      console.log(error);
    }
  };

  return (
    <div className="group w-full">
      {title === "Professional" && (
        <div className="bg-blue w-full group-hover:scale-105 py-3 rounded-tr-lg rounded-tl-lg text-center text-lg text-white font-[500]">
          <h2>Most Popular</h2>
        </div>
      )}
      <div
        className={`w-full mx-auto border  group-hover:bg-blue  group-hover:text-white hover:scale-105 rounded-lg shadow-lg p-6 md:p-8 lg:p-10 lg:px-18 cursor-pointer transition-colors duration-300 ${
          isActive
            ? "bg-blue text-white border-blue-500"
            : "bg-transparent text-blue border-blue"
        } ${title === "Professional" ? "-mt-2 hover:scale-100" : "mt-0"}`}
      >
        <div className="text-center">
          <div
            className={`font-semibold flex items-center justify-center py-3  border rounded-lg border-blue hover:border-white mb-2 text-sm md:text-base lg:text-xl ${
              isActive ? "text-white border-white" : ""
            }`}
          >
            {title}
          </div>
          <div
            className={`text-2xl md:text-3xl lg:text-4xl font-semibold  ${
              isActive ? "text-white" : ""
            }`}
          >
            {title !== "Enterprise"
              ? `$${isMonthly ? subtitle : Math.floor((subtitle * 75) / 100)}`
              : subtitle}
          </div>
          <div className="text-md md:text-sm mb-6">User/Month</div>
          <ul className="mb-6 space-y-2">
            {features.map((feature, index) => (
              <li
                key={index}
                className="flex items-start justify-start md:justify-start"
              >
                <svg
                  className={`min-w-4 min-h-4 md:w-5 md:h-5 mr-2 ${
                    (isActive || index > 3) && ind === 0
                      ? "text-gray-300"
                      : "text-green-500"
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                <span
                  className={`text-sm md:text-base text-start capitalize ${
                    (isActive || index > 3) && ind === 0 ? "text-gray-300" : ""
                  }`}
                >
                  {feature}
                </span>
              </li>
            ))}
          </ul>
          {user?.subscription?.type === type &&
          user?.subscription?.plan === (isMonthly ? "monthly" : "yearly") ? (
            <Button className="bg-gray-300" disabled>
              Current Plan
            </Button>
          ) : (
            <Button
              children={title === "Enterprise" ? "Contact Us" : "Choose Plan"}
              isLoading={loading}
              disabled={loading}
              className="group-hover:bg-white group-hover:text-blue"
              onClick={
                title === "Enterprise"
                  ? () => navigate("/contact")
                  : user?.id
                  ? HandleSubscription
                  : () => navigate("/login")
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
