import { useEffect, useState } from "react";

import axiosInstance from "../../utils/axios-instance";

const StatsSetting = () => {
  const [data, setData] = useState<Record<string, any>>({});

  // -------------------------------- [ GET DETAILS ] --------------------------------
  useEffect(() => {
    const GetStats = async () => {
      try {
        const res = await axiosInstance.get("/stats/agency-chat-count");
        setData(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    GetStats();
  }, []);

  return (
    <div className="w-full pb-10">
      <div className="w-[50%] mx-auto">
        <h2 className="text-3xl text-blue font-bold">Stats</h2>

        <div className="w-full flex items-center justify-between space-x-4">
          <div className="flex-grow p-6 rounded-lg shadow-md">
            <p className="text-md text-gray-400 mb-2">
              Number of Agencies That have atleast 1 Chat
            </p>
            <h2>{data?.agencyChats}</h2>
          </div>
          <div className="flex-grow p-6 rounded-lg shadow-md">
            <p className="text-md text-gray-400 mb-2">Total Chats</p>
            <h2>{data?.totalChats}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsSetting;
