import Home_discover from '../components/sections/Home-discover.tsx'
import HomeVideoSection from '../components/sections/Home-video-section.tsx'
import HomeCardSection from '../components/sections/Home-card-section.tsx'
import HomeCrousal from '../components/sections/Home-Crousal.tsx'
import ChatBox from '../components/sections/Chat-Box.tsx'
import Header from "../layout/Header.tsx"
import Footer from '../layout/Footer.tsx'

const Home = () => {

  return (
    <>
      <div className='w-[100%] pt-5 flex flex-col items-center justify-center relative max-w-[1440px] mx-auto '>
        <Header className={"!bg-[#fff] border-none !fixed top-0"} />
        <Home_discover />
        <HomeCardSection />
        <HomeVideoSection />
        <HomeCrousal />
        <ChatBox />
      </div>
      <Footer />
    </>
  )
}

export default Home