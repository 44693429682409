import { createContext, useState } from "react";
import { ConversationType } from "../types/conversation-type";

interface ConversationListType {
    totalCount: number;
    data: ConversationType[]
}

type ValueChatContext = {
    setConversations: (conData: ConversationListType) => void
    setCurrentConversation: (conversation: ConversationType) => void
    currentConversation: ConversationType | null
    conversations: ConversationListType
};



export const ChatContext = createContext<ValueChatContext | undefined>(undefined);

const ChatProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [currentConversation, setCurrentConversation] = useState<ConversationType | null>(null)
    const [conversations, setConversations] = useState<ConversationListType | null>(null)

    return (
        <ChatContext.Provider value={{ setCurrentConversation, currentConversation, setConversations, conversations }}>
            {children}
        </ChatContext.Provider>
    )
};

export default ChatProvider;
