import { useContext } from "react";
import { AuthContext } from "../providers/auth-provider.tsx";

const UseAuth = () => {
  const authData = useContext(AuthContext)!;

  return authData;
};

export default UseAuth;
