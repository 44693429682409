import React from "react";
import StyledLgText from "../../common/components/styledLgText/StyledLgText.tsx";

const HomeCrousalCard = ({heading, imgurl2}) => {
  return (
    <div className="bg-white rounded-md self-stretch gap-5 flex flex-col items-start justify-center p-5">
      <StyledLgText children={heading} fontColor={"text-black"} />
      <div className="flex justify-start items-start">
        <img src={imgurl2} alt="people" className="h-14 w-14 rounded-full" />
      </div>
    </div>
  );
};

export default HomeCrousalCard;
