import { useEffect, useState } from "react"
import axiosInstance from "../../utils/axios-instance"
import { UserAgencyModelType } from "../../types/user-types"
import SingleAgencyDetails from "./single-agency-details"
import PaginationMenu from "../pagination/paginationMenu"
import Button from "../button/Button"
import CreateNewAgency from "../../models/create-new-agency"

interface IAgencies {
    totalCount: 0,
    data: UserAgencyModelType[]
}

const AgencySettings = () => {
    const [agencies, setAgencies] = useState<IAgencies>({
        totalCount: 0,
        data: []
    })
    const [page, setPage] = useState(1)
    const [limit] = useState(10)
    const [createAgency, setCreateAgency] = useState(false)

    // ------------------------------ [ FIND AGENCIES ] -------------------------------------
    useEffect(() => {
        const findAgencies = async () => {
            try {
                const res = await axiosInstance.get(`/agencies?limit=${limit}&page=${page}`)
                setAgencies(res.data)
            } catch (error) {
                console.log(error)
            }
        }

        findAgencies()
    }, [page, limit])


    // --------------------------------- [ SET PAGES ] --------------------------------

    const onSetPage = (page) => {
        setPage(page)
    }



    const ToggleAgencyPopup = () => [
        setCreateAgency(!createAgency)
    ]

    return (
        <div className='w-[90%] mx-auto'>

            <div className="w-full flex justify-end mb-4">
                <Button className="w-auto !bg-black" onClick={ToggleAgencyPopup}>Create Agency</Button>
            </div>
            <table className='w-full'>
                <div className='grid grid-cols-6 gap-x-3 bg-blue text-white py-4 px-6 rounded-tl-md rounded-tr-md'>
                    <th className='text-[1.2rem] font-[500] text-start'>Name</th>
                    <th className='text-[1.2rem] font-[500]'>Email</th>
                    <th className='text-[1.2rem] font-[500]'>Users</th>
                    <th className='text-[1.2rem] font-[500]'>Subscription</th>
                    <th className='text-[1.2rem] font-[500]'>Status</th>
                    <th className='text-[1.2rem] font-[500] text-end'>Joined On</th>
                </div>
                <tbody>
                    {agencies.data.map((item) => {
                        return (
                            <SingleAgencyDetails item={item} key={item.id} />
                        )
                    })}
                    <PaginationMenu onPageChange={onSetPage} totalCount={agencies.totalCount} currentPage={page} pageSize={limit} />
                </tbody>
            </table>

            {createAgency ? <CreateNewAgency toggleUserModel={ToggleAgencyPopup} /> : null}
        </div>
    )
}

export default AgencySettings
