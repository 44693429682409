import { useState } from "react";
import Button from "../components/button/Button";
import { ParseErrors } from "../utils/parse-errors";
import { toast } from "react-toastify";
import axiosInstance from "../utils/axios-instance";
import ExporttoText from "../utils/export-file";

interface IExportConversationModel {
  id: number;
  toggleModel: () => void;
}

const ExportConversationModel = ({
  id,
  toggleModel,
}: IExportConversationModel) => {
  const [loading, setLoading] = useState(false);
  // -------------------------------------------------- DOWNLOAD USER DATA --------------------------------

  const downloadUserData = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.post(
        `/chat/export/user/conversation/${id}`
      );
      ExporttoText(res.data.data)
      toast.success("Conversation Exported Successfully!");
      toggleModel();
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(ParseErrors(error));
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full fixed top-0 bg-[rgba(0,0,0,0.5)] left-0 z-50 flex items-center justify-center">
      <div className="p-6  rounded-md bg-white shadow-sm min-w-[500px]">
        <h2 className="text-2xl font-[500]">
          {loading
            ? "Exporting Data ......"
            : "Are you sure you want to Export ?"}
        </h2>

        <div className="mt-8 flex items-center justify-between">
          <Button
            className="w-auto bg-black"
            onClick={toggleModel}
            disabled={loading}
            isLoading={loading}
          >
            Cancel
          </Button>
          <Button
            className="w-auto bg-red-500"
            onClick={downloadUserData}
            disabled={loading}
            isLoading={loading}
          >
            Export
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExportConversationModel;
