import {
  faChartBar,
  faHandScissors,
  faPen,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

export const content = {
  leadingCompanies: [
    {
      imagUrl: "/assets/images/allianz.png",
    },
    {
      imagUrl: "/assets/images/kpmg.png",
    },
    {
      imagUrl: "/assets/images/orange.png",
    },
    {
      imagUrl: "/assets/images/logo-smart4-color.png",
    },
  ],
  whyUs: [
    {
      head: "Precision-Engineered Platform:",
      content: "Designed for optimal business coaching effectiveness.",
    },
    {
      head: "Professional Growth:",
      content:
        "Tools that rival traditional coaching methods to enhance your development.",
    },
    {
      head: "Convenient and Effective:",
      content: "Access cutting-edge coaching anytime, anywhere.",
    },
  ],
  cards: [
    {
      imgUrl: "/assets/images/card1.png",
      head: "Conflict Resolution",
      content:
        "Learn  professional conflict management strategies to resolve workplace disputes.",
    },
    {
      imgUrl: "/assets/images/card3.png",
      head: "Stress Management",
      content:
        "Provide techniques and support for managing stress and maintaining work-life balance.",
    },
    {
      imgUrl: "/assets/images/card2.png",
      head: "Performance Enhancement",
      content:
        "Analyze and improve individual and team performance through targeted coaching and actionable insights.",
    },
  ],
  discover: [
    {
      imgUrl: "/assets/icons/Feather.png",
      head: "Unlock Powerful Features Today",
      content:
        "Discover the core functionalities of Coach AI and unleash the potential of AI-driven software creation.",
    },
    {
      imgUrl: "/assets/icons/Magnet.png",
      head: "Streamline Your Workflow",
      content:
        "Experience seamless integration and innovative iconography that simplifies software development.",
    },
    {
      imgUrl: "/assets/icons/LinkBreak.png",
      head: "Unleash Your Creativity",
      content:
        "Embrace dynamic graphics and unleash your creativity with our AI-driven platform.",
    },
  ],
  crasual: [
    {
      head: "Coach AI has been really helpful. My tip: use the daily prompts and feedback consistently. It makes staying on track with your goals much easier. –– DL",
      imgUrl2: "/assets/images/girl.png",
    },
    {
      head: "Before diving into the app, take a few minutes to clearly define what you want to achieve. Whether it's improving leadership skills, managing stress, or enhancing productivity, having specific goals will help the AI coach tailor its advice and resources to best suit your needs. –– JD",
      imgUrl2: "/assets/images/man.png",
    },
    {
      head: "Consistency is key to making progress. Try to interact with the app daily or at least a few times a week. Regular engagement with the chat will help reinforce positive habits, track your progress, and allow the AI to adjust its coaching to your evolving needs. –– CA",
      imgUrl2: "/assets/images/man2.png",
    },
    {
      head: "Coach AI is a solid tool. My advice: be honest with your inputs and take the suggestions seriously. It’s a simple way to keep yourself moving forward. –– KJ",
      imgUrl2: "/assets/images/grl-2.png",
    },
    {
      head: "The effectiveness of the AI coach relies on the information you provide. Be as honest and accurate as possible when responding to questions or providing feedback. This will ensure that the guidance you receive is personalized and truly beneficial. –– ML",
      imgUrl2: "/assets/images/by-2.png",
    },
  ],
  menuItems: [
    {
      head: "Pricing",
      url: "/pricing",
    },
    // {
    //   head: "Demo",
    //   url: "/demo",
    // },
    {
      head: "Contact",
      url: "/contact",
    },
  ],
  chatHeader: [
    {
      head: "Settings",
      url: "/user-setting",
    },
    {
      head: "Best Practices",
      url: "/practices",
    },
    {
      head: "Blog",
      url: "/blog",
    },
  ],
  footerItems: [
    {
      head: "About",
    },
    {
      head: "Features",
    },
    {
      head: "Our process",
    },
    {
      head: "Benefits",
    },
    {
      head: "Blog",
    },
    {
      head: "Contact",
    },
    {
      head: "Course",
    },
  ],

  pricingPlans: [
    {
      title: "Starter",
      type: "starter",
      subtitle: 28,
      features: ["1 user", "unlimited chats"],
    },
    {
      title: "Growth",
      subtitle: 22,
      type: "growth",
      features: [
        "5 users",
        "unlimited chats",
        "30 minute onboarding with a professional coach",
      ],
    },
    {
      title: "Professional",
      type: "professional",
      subtitle: 18,
      features: [
        "15 users",
        "unlimited chats",
        "1 hour onboarding with a professional coach",
      ],
    },
    {
      title: "Enterprise",
      subtitle: "Let's Talk",
      features: [
        "Customized Number of Users",
        "Unlimited Chats",
        "Several onboarding sessions with a professional coach",
        "Monthly customized organizational activity report",
        "30 minute debrief per month with a professional coach",
      ],
    },
  ],
};

export const chatStarter = [
  {
    icon: faHandScissors,
    iconColor: "#E2C541",
    title: "Conflict Resolution",
    message: "Hello, can you help me with conflict resolution?",
  },
  {
    icon: faUsers,
    iconColor: "#76D0EB",
    title: "Stress Management",
    message: "I need help with stress management, could you advise?",
  },
  {
    icon: faPen,
    iconColor: "#C98ACE",
    title: "Giving Someone Feedback",
    message: "I am unsure how to go about giving feedback to someone.",
  },
  {
    icon: faChartBar,
    iconColor: "#75CFE9",
    title: "Performance Improvement",
    message: "Hi, I would like to improve my performance at work.",
  },
];

export const flags = [
  {
    imagUrl: "/assets/images/ukflag.avif",
  },
  {
    imagUrl: "/assets/images/usflag.jpeg",
  },
  {
    imagUrl: "/assets/images/Flag-France.webp",
  },
  {
    imagUrl: "/assets/images/itly flag.png",
  },
  {
    imagUrl: "/assets/images/german flag.png",
  },
  {
    imagUrl: "/assets/images/spain flag.png",
  },
];
