import { useContext } from "react";
import { ChatContext } from "../providers/chat-provider.tsx";

const UseChat = () => {
  const chatData = useContext(ChatContext)!;

  return chatData;
};

export default UseChat;
