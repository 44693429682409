import React from "react";
import StyledH1Heading from "../../common/components/styledH1Heading/StyledH1Heading.tsx";
import StyledLgText from "../../common/components/styledLgText/StyledLgText.tsx";
import Button from "../button/Button.tsx";
import { NavLink } from "react-router-dom";

const ChatBox = () => {
  return (
    <div className="w-full flex py-12 items-center justify-center">
      <div className="bg-white flex lg:flex-row flex-col w-[90%] py-12 justify-around items-center">
        <div className=" lg:w-[60%] flex flex-col justify-start item-start gap-3">
          <StyledH1Heading
            fontColor={"text-blue"}
            content={
              "Chat about what’s on your mind effortlessly for your professional success"
            }
            customColor={""}
            diffText={""}
          />
          <StyledLgText
            children={
              "Unlock the power of AI technology to revolutionize your software development process."
            }
            fontColor={"text-lightBlack"}
          />
          <div className="lg:w-1/4">
            <NavLink to={"/chats"}>

              <Button
                children={"Get Started"}

                disabled={false}
              />
            </NavLink>
          </div>
        </div>
        <img src="/assets/images/ChatRobo.png" alt="robo" />
      </div>
    </div>
  );
};

export default ChatBox;
