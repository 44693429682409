import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import Button from '../components/button/Button'
import { toast } from 'react-toastify';
import { ParseErrors } from '../utils/parse-errors';
import axiosInstance from '../utils/axios-instance';

interface ICreateNewAgency {
    toggleUserModel: () => void;
}

const CreateNewAgency = ({ toggleUserModel }: ICreateNewAgency) => {
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState<Record<string, any>>({})

    const onCreateUser = async () => {
        setLoading(true)
        try {
            await axiosInstance.post('/auth/register', {...values,internal:true})
            toggleUserModel()
            setLoading(false)
        } catch (error) {
            toast.error(ParseErrors(error))
            toggleUserModel()
            console.log(error)
            setLoading(false)
        }
    }

    // ---------------------------------- [ON CHANGE VALUES] --------------------------------
    const HandleOnChange = (e) => {
        setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }

    return (
        <div className='w-full h-full fixed top-0 bg-[rgba(0,0,0,0.5)] left-0 z-50 flex items-center justify-center'>
            <div className='p-6  rounded-md bg-white shadow-sm min-w-[500px]'>
                <div className="flex items-center justify-between">
                    <h2 className='text-2xl font-[500] text-black'>Create Agency</h2>

                    <FontAwesomeIcon onClick={toggleUserModel} icon={faXmark} className="text-black text-2xl cursor-pointer" />
                </div>

                <div className='w-full mt-6 space-y-4'>
                    <div>
                        <label className='text-black text-md font-[500]'>Name</label>
                        <input name="name" onChange={HandleOnChange} className='mt-1 w-full h-[50px] border-gray-200 border-[1px] rounded-sm px-4' />
                    </div>
                    <div>
                        <label className='text-black text-md font-[500]'>Email</label>
                        <input name="email" onChange={HandleOnChange} className='mt-1 w-full h-[50px] border-gray-200 border-[1px] rounded-sm px-4' />
                    </div>
                    <div>
                        <label className='text-black text-md font-[500]'>Password</label>
                        <input name="password" onChange={HandleOnChange} className='mt-1 w-full h-[50px] border-gray-200 border-[1px] rounded-sm px-4' />
                    </div>
                    <Button isLoading={loading} disabled={loading} onClick={onCreateUser} className='mt-8'>Submit</Button>
                </div>
            </div>
        </div>
    )
}

export default CreateNewAgency
