import { useEffect, useRef, useState } from "react";
import StyledH3Heading from "../common/components/styledH3Heading/styledH3Heading.tsx";
import axiosInstance from "../utils/axios-instance.ts";
import moment from "moment";
import UseChat from "../utils/use-chat.ts";
import { toast } from "react-toastify";
import { ParseErrors } from "../utils/parse-errors.ts";
import UseAuth from "../utils/use-auth.ts";
import clsx from "clsx";
import RemoveHtmlTags from "../utils/remove-html-tags.ts";
import { chatStarter, flags } from "../data.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TypingAnimation from "../components/typing-animation.tsx";

export interface ISingleMessage {
  id?: number;
  conversationId: number;
  userId: number;
  message: string;
  type: "human" | "ai";
  createdAt?: string;
  updatedAt?: string;
}

interface IMessages {
  totalCount: number;
  data: ISingleMessage[];
}

const ChatWindow = () => {
  const { currentConversation, setCurrentConversation } = UseChat();
  const { user } = UseAuth();
  const [value, setValue] = useState("");
  const [messages, setMessages] = useState<IMessages>({
    totalCount: 0,
    data: [],
  });
  const lastMessageRef = useRef(null);
  const [isSending, setIsSending] = useState<boolean>(false);

  const handleSendMessage = async (message?: any) => {
    let newValue = message ? message : value;
    setIsSending(true);
    let conversationId = currentConversation?.id;
    setValue("");
    if (newValue?.trim()) {
      if (!currentConversation?.id) {
        try {
          const res = await axiosInstance.post("/chat/conversation/create", {
            message: newValue,
          });
          setCurrentConversation(res.data.data);
          conversationId = res.data.data?.id;
        } catch (error) {
          console.log(error);
          toast.error(ParseErrors(error));
          setIsSending(false);
        }
      }

      // ------------------------- [ PUSH MESSAGE ] ---------------------------------
      const newMessage: ISingleMessage = {
        message: newValue,
        conversationId: conversationId,
        userId: user?.id,
        type: "human",
        createdAt: new Date().toString(),
      };

      setMessages((prev) => ({ ...prev, data: [...prev.data, newMessage] }));

      try {
        const stream = await fetch(
          process.env.REACT_APP_API_URL + "/chat/message",
          {
            method: "POST",
            body: JSON.stringify({
              ...newMessage,
              messagesArray: messages.data.map((item) => ({
                role: item.type === "ai" ? "assistant" : "user",
                content: item?.message,
              })),
            }),
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        const streamRes = stream.body
          .pipeThrough(new TextDecoderStream())
          .getReader();
        let data = "";
        setIsSending(false);

        while (true) {
          const { done, value } = await streamRes.read();
          if (done) {
            break;
          }
          data += value;
          setMessages({
            totalCount: messages.totalCount,
            data: [
              ...messages.data,
              newMessage,
              {
                message: data,
                conversationId: conversationId,
                userId: user?.id,
                type: "ai",
              },
            ],
          });
          lastMessageRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }

        setIsSending(false);
      } catch (error) {
        console.log(error);
        setIsSending(false);
      }
    } else {
      setIsSending(false);
    }
  };

  // ------------------------ [ FIND CONVERSATION MESSAGES ] ----------------------

  useEffect(() => {
    const findMessages = async () => {
      try {
        const res = await axiosInstance.get(
          `/chat/conversation/messages/${currentConversation?.id}`
        );
        setMessages(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    currentConversation?.id && findMessages();
  }, [currentConversation?.id]);

  // ------------------------ [ UPDATE CONVERSATION ] ----------------------

  useEffect(() => {
    const updateConversations = async () => {
      try {
        const res = await axiosInstance.put(
          `/chat/conversation/${currentConversation?.id}`,
          { message: messages.data.pop().message }
        );
        setCurrentConversation(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    currentConversation?.id &&
      messages.data.length === 3 &&
      updateConversations();
  }, [currentConversation?.id, messages.data.length]);

  // ----------------------- [ ON ENTER SEND MESSAGE ] --------------------------
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && value && !isSending) {
      handleSendMessage();
    }
  };

  // ---------------------------- [ SCROLL TO BOTTOM ] -------------------------
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [messages?.data?.length]);

  useEffect(() => {
    if (!currentConversation?.id) {
      setMessages({ totalCount: 0, data: [] });
    }
  }, [currentConversation]);

  return (
    <div className="w-[100%] lg:w-[80%] h-full bg-white flex flex-col">
      {currentConversation?.id ? (
        <div className="w-[100%] border-b">
          <div className="w-[95%] mx-auto flex justify-between items-center py-3">
            <StyledH3Heading
              children={
                RemoveHtmlTags(
                  currentConversation?.name.replaceAll('"', "")
                ).slice(0, 90) + "..." || "Chat"
              }
              fontColor={"text-black !text-[25px]"}
            />
            <div className="flex items-center justify-center gap-2">
              <p className="text-md font-normal text-black">English</p>
              <img src="/assets/icons/ic_chevron.png" alt="" />
            </div>
          </div>
        </div>
      ) : null}

      {!currentConversation?.id ? (
        <div className="h-full w-full items-center justify-center flex flex-col">
          <div className="w-[400px] chat_display_img relative">
            <img src="/assets/images/chat-icon.jpg" />
          </div>
          <div className="w-[400px] pt-[16px] flex gap-8 justify-center items-center">
            {flags.map((item) => {
              return (
                <div
                  key={item.imagUrl}
                  className="w-[100px] bg-white flex justify-center items-center cursor-pointer hover:bg-[rgba(245,245,245)] transition-all ease-linear text-center"
                >
                  <img src={item.imagUrl} className="w-full" />
                </div>
              );
            })}
          </div>
          <h2 className="text-2xl font-[500] mt-10">Conversations Starters</h2>
          <div className="w-[90%] mx-auto grid grid-cols-4 gap-4 mt-10">
            {chatStarter.map((item) => {
              return (
                <div
                  key={item.title}
                  className={clsx(
                    "w-full px-6 py-10 border-[1px] border-gray-200 rounded-md cursor-pointer hover:bg-[rgba(245,245,245)] transition-all ease-linear text-center",
                    isSending && "pointer-events-none opacity-[0.6]"
                  )}
                  onClick={() => {
                    handleSendMessage(item.message);
                  }}
                >
                  <FontAwesomeIcon
                    icon={item.icon}
                    size="2x"
                    color={item.iconColor}
                  />
                  <h2 className="mt-4 text-lg font-[400]">{item.title}</h2>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="w-[100%] h-[100%] overflow-y-auto">
          {messages.data.length ? (
            <div className="w-[95%] mx-auto p-4">
              {messages?.data?.map((msg, index) => (
                <>
                  <div
                    key={index}
                    className={`message_box flex ${
                      msg.type === "human" ? "justify-end" : "justify-start"
                    } mb-2`}
                  >
                    <div
                      className={`p-5 px-10 r ${
                        msg.type === "human"
                          ? "bg-blue p-2 rounded-t-xl rounded-l-xl  text-white"
                          : "bg-[#F5F5F5] rounded-t-xl rounded-r-xl text-black"
                      }`}
                    >
                      <div
                        className="space-y-2"
                        dangerouslySetInnerHTML={{ __html: msg.message }}
                      ></div>
                    </div>
                  </div>
                  <span
                    className={clsx(
                      "text-xs flex text-gray-500",
                      msg.type === "human" ? "justify-end" : "justify-start"
                    )}
                  >
                    {moment(msg.createdAt).format("HH:MM")}
                  </span>
                </>
              ))}
              <div ref={lastMessageRef}></div>
              {isSending ? (
                <div className="mt-6">
                  <TypingAnimation />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
      <div className="p-4 justify-around border-t border-gray-200 flex items-center">
        <div className="flex w-[90%]">
          <input
            type="text"
            onKeyDown={handleKeyDown}
            className="flex-grow h-[45px] px-6 border border-gray-300 rounded-full mr-2"
            placeholder="Start a new message"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <button
          disabled={isSending}
          className="bg-[transparent] border-none outline-none"
        >
          <img
            onClick={handleSendMessage}
            src="/assets/icons/Vector.png"
            alt="send"
          />
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;
