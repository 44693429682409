import { ChangeEvent, useEffect, useState } from "react";
import Header from "../layout/Header";
import { toast } from "react-toastify";
import { ParseErrors } from "../utils/parse-errors";
import axiosInstance from "../utils/axios-instance";
import Button from "../components/button/Button";
import Footer from "../layout/Footer";
import { useSearchParams } from "react-router-dom";

const Contact = () => {
  const [params, setParams] = useSearchParams();
  const [values, setvalues] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);

  const Handlesubmit = async () => {
    if (validate()) {
      setLoading(true);
      try {
        const res = await axiosInstance.post("/contact/form", values);
        toast.success("Form submitted successfully!");
        setLoading(false);
        setvalues({});
      } catch (error) {
        console.log(error);
        toast.error(ParseErrors(error));
        setLoading(false);
      }
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setvalues({ ...values, [e.target.name]: e.target.value });
  };

  const validate = () => {
    if (!values?.firstName || !values?.lastName) {
      toast.error("Please enter full name");
      return false;
    } else if (!values?.email) {
      toast.error("Please enter email");
      return false;
    } else if (!values?.message) {
      toast.error("Please enter message");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (params.has("email")) {
      const email = params.get("email");
      setvalues({ ...values, email });
      params.delete("email");
      setParams(params);
    }
  }, []);

  return (
    <>
      <Header />
      <div className="w-full">
        <div className="w-[90%] max-w-[1440px] mx-auto bg-white py-20">
          <div className="py-10 px-6 text-center">
            <h1 className="text-3xl font-bold">Contact Us</h1>
            <p className="w-[60%] text-md text-gray-500 mx-auto">
              We’re here to help! Whether you have a question, need support, or
              just want to share your feedback, our team is ready to assist you.
              Reach out to us through the form below, we typically respond
              within 24 hours and look forward to connecting with you!
            </p>
          </div>
          <div className="py-4 w-[70%] mx-auto">
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-x-10">
                <div className="w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    First Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3"
                    type="text"
                    placeholder="Your First Name"
                    name="firstName"
                    value={values?.firstName || ""}
                    onChange={onChange}
                  />
                </div>
                <div className="w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Last Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3"
                    type="text"
                    placeholder="Your Last Name"
                    name="lastName"
                    value={values?.lastName || ""}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-x-10">
                <div className="w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Email
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3"
                    type="text"
                    placeholder="Your Email"
                    name="email"
                    value={values?.email || ""}
                    onChange={onChange}
                  />
                </div>
                <div className="w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Phone Number
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3"
                    type="text"
                    placeholder="Your Phone Number"
                    name="phone"
                    value={values?.phone || ""}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-x-10">
                <div className="w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Occupation
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3"
                    type="text"
                    placeholder="Your Occupation"
                    name="occupation"
                    value={values?.occupation || ""}
                    onChange={onChange}
                  />
                </div>
                <div className="w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Company
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3"
                    type="text"
                    placeholder="Your Company"
                    name="company"
                    value={values?.company || ""}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 gap-x-10">
                <div className="w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Message
                  </label>
                  <textarea
                    className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 h-[120px]"
                    placeholder="Type Message"
                    name="message"
                    value={values?.message || ""}
                    onChange={onChange}
                  />
                </div>
              </div>

              <Button
                onClick={Handlesubmit}
                isLoading={loading}
                disabled={loading}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
