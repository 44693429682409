import React, { useState } from "react";
import { ParseErrors } from "../../utils/parse-errors";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axios-instance";
import UseAuth from "../../utils/use-auth";
import StyledMdText from "../../common/components/styledMdText/StyledMdText";
import Button from "../button/Button";
import SuretyModel from "../../models/surety-model";

const UserSettingsTab = () => {
  const { UpdateLoginData, HandleUserLogOut, user } = UseAuth();
  const [values, setValues] = useState<Record<string, any>>({});
  const [profilePicture, setProfilePicture] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const HandleUpdateMe = async () => {
    if (validate()) {
      setLoading(true);
      try {
        const res = await axiosInstance.patch("/user/update/me", { ...values });
        toast.success("User Updated Successfully");
        UpdateLoginData({ isLoggedIn: true, data: res.data.data });
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error(ParseErrors(error));
        setLoading(false);
      }
    }
  };

  const HandleValues = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const validate = () => {
    if (!values.cpassword) {
      toast.error("Please enter current password to update");
      return false;
    } else {
      return true;
    }
  };

  const toggleDeletePopup = () => {
    setIsDelete((prev) => !prev);
  };

  const HandleDeleteMe = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.delete("/auth/delete");
      toast.success(res.data.message);
      HandleUserLogOut();
      toggleDeletePopup();
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(ParseErrors(error));
      setLoading(false);
    }
  };

  return (
    <div className=" p-8 rounded-lg  w-[50%] mx-auto ">
      <div className="flex flex-col items-center mb-6">
        <div className="relative w-24 h-24">
          {profilePicture ? (
            <img
              src={profilePicture}
              alt="Profile"
              className="w-24 h-24 rounded-full object-cover"
            />
          ) : (
            <div className="w-24 h-24 rounded-full bg-blue-200 flex items-center justify-center">
              <img className="" src="/assets/icons/edit.png" alt="edit" />
            </div>
          )}
          <input
            type="file"
            className="absolute bottom-9 w-full h-full opacity-0 cursor-pointer"
            onChange={handleProfilePictureChange}
          />
        </div>
        <h2 className="text-2xl font-bold text-center text-blue mt-4">
          User Settings
        </h2>
      </div>
      <div className="space-y-4">
        <div>
          <StyledMdText children={"User Name"} fontColor={"text-black"} />
          <input
            type="text"
            name="name"
            className="w-full p-4 rounded-lg border border-gray-300 bg-gray-50 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            defaultValue={user?.name}
            onChange={HandleValues}
          />
        </div>
        <div>
          <StyledMdText children={"Email"} fontColor={"text-black"} />
          <input
            type="email"
            name="email"
            className="w-full p-4 rounded-lg border border-gray-300 bg-gray-50 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            defaultValue={user?.email}
            onChange={HandleValues}
          />
        </div>
        <div>
          <StyledMdText
            children={"Current Password"}
            fontColor={"text-black"}
          />

          <input
            type="password"
            name="cpassword"
            className="w-full p-4 rounded-lg border border-gray-300 bg-gray-50 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={values?.cpassword}
            onChange={HandleValues}
          />
        </div>
        <div>
          <StyledMdText children={"New Password"} fontColor={"text-black"} />
          <input
            type="password"
            name="password"
            className="w-full p-4 rounded-lg border border-gray-300 bg-gray-50 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={values?.password}
            onChange={HandleValues}
          />
        </div>
        <Button
          children={"Save"}
          disabled={loading}
          isLoading={loading}
          onClick={HandleUpdateMe}
        />

        <div className="pt-6 border-t-[1px] border-t-gray-200 !mt-8">
          <Button
            children={"Delete Account"}
            className="!bg-red-600"
            disabled={loading}
            isLoading={loading}
            onClick={toggleDeletePopup}
          />
        </div>
      </div>

      {isDelete ? (
        <SuretyModel
          toggleModel={toggleDeletePopup}
          onClick={HandleDeleteMe}
          isLoading={loading}
        />
      ) : null}
    </div>
  );
};

export default UserSettingsTab;
