import React, { useState } from "react";
import StyledMdText from "../common/components/styledMdText/StyledMdText.tsx";
import { content } from "../data.tsx";
import Button from "../components/button/Button.tsx";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState<string | null>();
  const navigate = useNavigate();

  return (
    <div className="lg:h-[20vh] bg-blue w-full flex justify-center items-center">
      <div className="w-[90%] max-w-[1440px] mx-auto justify-between flex lg:flex-row gap-4 flex-col items-center">
        <div className="lg:w-[40%] gap-4 lg:gap-10 flex flex-col justify-between items-start">
          {/* <StyledMdText children={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "} fontColor={"text-white"} /> */}

          <StyledMdText fontColor="text-white">
            &#169; {currentYear} AI Coach. All rights reserved.
          </StyledMdText>
        </div>
        <div className=" lg:w-[20%] gap-6 grid grid-flow-cols grid-cols-2 grid-rows-4">
          {/* {content.footerItems.map((item, index) => (
                        <div key={index} className='text-white hover:text-lightBlack cursor-pointer text-md justify-center items-center'>
                            {item.head}
                        </div>
                    ))} */}
        </div>
        <div className=" flex  items-center justify-center gap-3 ">
          <input
            placeholder="youremail@email.com"
            className="bg-white text-lightBlack text-center rounded-lg h-12 "
            onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
            ></input>
          <Button
            disabled={!email}
            children={"Get Started"}
            onClick={() => navigate(`/contact?email=${email}`)}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
