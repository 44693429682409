import React from 'react'

const CustonHeding = ({ fontColor, content, customColor, diffText ,content2}) => {
    return (
        <h3 className={`${fontColor} font-semibold leading-snug md:leading-tight  `}>
          {content} {" "}
              <span className={`${customColor}  `}>
              {diffText}
              </span>{" "}
            {content2}
        </h3>
      );
    };

export default CustonHeding