import React from "react";
import Button from "../components/button/Button";

interface ISuretyModel {
  type?: "unpaid" | "no-subscription";
  toggleModel: () => void;
}

const SubscriptionInfoModel = ({ type, toggleModel }: ISuretyModel) => {
  return (
    <div className="w-full h-full fixed top-0 bg-[rgba(0,0,0,0.5)] left-0 z-50 flex items-center justify-center">
      <div className="p-6  rounded-md bg-white shadow-sm min-w-[500px]">
        <h2 className="text-2xl font-[500] capitalize">
          {type === "no-subscription" ? "Please Buy a Subscription to Continue" : `Please Update your subscription it's ${type}`}
        </h2>

        <div className="mt-8 flex items-center justify-end">
          <Button className="w-auto bg-black" onClick={toggleModel}>
            Sure
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionInfoModel;
