import CustonHeding from "../../common/components/customHeading/CustonHeding.tsx";
import HomeCard from "../cards/Home-card.tsx";
import { content } from "../../data.tsx";

const HomeCardSection = () => {
  return (
    <div className="w-[90%] flex flex-col justify-center items-center">
      <div className="lg:w-[55%] text-center flex justify-center items-center">
        <CustonHeding
          fontColor={"text-black text-xl  lg:text-3xl "}
          content={""}
          customColor={"text-blue font-bold text-xl lg:text-3xl"}
          diffText={"Popular Use Cases"}
          content2={""}
        />
      </div>

      <div className="flex lg:flex-row flex-col w-[98%] gap-6 justify-around items-start">
        {content?.cards?.map((item, index) => (
          <HomeCard
            key={index}
            imgSrc={item.imgUrl}
            headingContent={item.head}
            paraContent={item.content}
          />
        ))}
      </div>
    </div>
  );
};

export default HomeCardSection;
