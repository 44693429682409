import React, { useState } from 'react'
import axiosInstance from '../utils/axios-instance';
import { toast } from 'react-toastify';
import { ParseErrors } from '../utils/parse-errors';
import { useNavigate } from 'react-router-dom';
import StyledMdText from '../common/components/styledMdText/StyledMdText';
import Button from '../components/button/Button';

const ForgotPassword = () => {
    const [values, setValues] = useState<Record<string, any>>({})
    const [loading, setLoading] = useState<boolean>(false);
    const NavigateTo = useNavigate()

    const HandleSubmit = async () => {
        if (validate()) {
            setLoading(true)
            try {
                const response = await axiosInstance.post('/auth/forgot/password', { ...values })
                toast.success(response.data.message)
                setLoading(false)
            } catch (error) {
                console.log(error)
                toast.error(ParseErrors(error))
                setLoading(false)
            }
        }
    }


    const HandleChangeValues = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    }


    const validate = () => {
        if (!values.email) {
            toast.error("Please enter email address")
            return false
        } else {
            return true
        }
    }

    return (
        <div className='w-[full] bg-white flex justify-center items-center'>
            <img className='lg:flex hidden lg:w-[50%] h-screen' src='/assets/images/signup.png' alt='Signup' />

            <div className=" md:w-[60%] lg:w-[50%] flex flex-col items-center justify-center py-[20%] lg:py-0 p-4">
                <div className=" bg-white p-8 rounded-lg  w-full lg:max-w-md">
                    <h2 className="text-2xl font-bold text-center text-blue mb-6">Forgot Password</h2>
                    <div className="space-y-3">
                        <StyledMdText children={"Email"} fontColor={'text-black'} />

                        <input
                            type="email"
                            name='email'
                            placeholder="Please enter your email"
                            className="w-full p-3 rounded-lg border bg-[#F1F1F1] border-gray-300"
                            value={values?.email}
                            onChange={HandleChangeValues}
                        />


                        <Button children={"Submit"} onClick={HandleSubmit} disabled={loading} isLoading={loading} />
                    </div>
                    <div className="text-center mt-4">
                        <p className="text-sm text-gray-600">Already Registered?</p>
                        <Button children={"Sign In"} onClick={() => NavigateTo("/login")} disabled={loading} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ForgotPassword