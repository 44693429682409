import ChatList from "../layout/Chat-list.tsx";
import ChatWindow from "../layout/Chat-window.tsx";
import Header from "../layout/Header.tsx";

const Chat = () => {
  return (
    <div className="flex flex-col flex-grow h-[100vh]">
      <Header className="!max-w-[100%]" />
      <div className="flex h-[calc(100vh-80px)]">
        <ChatList />
        <ChatWindow />
      </div>
    </div>
  );
};

export default Chat;
