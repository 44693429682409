import React from "react";
import StyledH3Heading from "../../common/components/styledH3Heading/styledH3Heading.tsx";
import StyledLgText from "../../common/components/styledLgText/StyledLgText.tsx";
import Button from "../button/Button.tsx";
import { useNavigate } from "react-router-dom";
import UseAuth from "../../utils/use-auth.ts";

const HomeCard = ({ imgSrc, headingContent, paraContent }) => {
  const { user } = UseAuth();
  const navigate = useNavigate();

  return (
    <div className="w-full p-8 mt-10 gap-5 flex flex-col justify-start items-start rounded-lg bg-white text-center self-stretch shadow-lg">
      <img className="w-[50%] mx-auto" src={imgSrc} alt="Card image" />

      <StyledH3Heading
        children={headingContent}
        fontColor={"text-blue text-2xl w-[70%] mx-auto"}
      />

      <StyledLgText children={paraContent} fontColor={"text-lightBlack w-[90%] mx-auto"} />
      <Button
        className="mx-auto block w-auto !px-10"
        children={"Try Now"}
        onClick={() => navigate(user?.id ? "/chats" : "/signup")}
      />
    </div>
  );
};

export default HomeCard;
