import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import Button from '../button/Button'
import { faBan, faChevronDown, faChevronUp, faTrash } from '@fortawesome/free-solid-svg-icons'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { ParseErrors } from '../../utils/parse-errors'
import axiosInstance from '../../utils/axios-instance'
import SuretyModel from '../../models/surety-model'

const SingleUser = ({ item: itemData, className }: any) => {
    const [item, setItem] = useState(itemData)
    const [values, setValues] = useState({})
    const [expanded, setExpanded] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)
    const [deletePopup, setDeletePopup] = useState(false)
    // const [blockPopup, setBlockPopup] = useState(false)


    // --------------------------------- [ UPDATE USER ] ------------------------------------------------------------------
    const UpdateUser = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.patch(`/user/update/user/${item.id}`, values)
            toast.success("User updated successfully")
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
            toast.error(ParseErrors(error))
        }
    }


    // --------------------------------- [ UPDATE USER ] ------------------------------------------------------------------
    const DeleteUser = async () => {
        setLoading(true)
        try {
            await axiosInstance.delete(`/user/delete/user/${item.id}`)
            setItem(null)
            toast.success("User deleted successfully")
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
            toast.error(ParseErrors(error))
        }
    }


    const ToggleDeletePopup = () => {
        setDeletePopup(!deletePopup)
    }
    // const ToggleBlockPopup = () => {
    //     setBlockPopup(!blockPopup)
    // }


    if (!item?.id) {
        return null
    }

    return (
        <div className={clsx("w-full cursor-pointer  border-[1px] bg-white border-gray-200 rounded-lg overflow-hidden last:border-b-[1px]  my-4", className)}>
            <div className={clsx("w-full flex items-center justify-between p-4", expanded && "!bg-gray-100 text-black")} onClick={() => setExpanded(!expanded)}>
                <div className="flex items-center justify-start">
                    <div className="h-[50px] w-[50px] rounded-full overflow-hidden mr-4">
                        <img src="https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?size=626&ext=jpg&ga=GA1.1.2116175301.1717804800&semt=ais_user" alt="" className="w-full h-full object-cover rounded-full" />
                    </div>
                    <h2 className="text-lg font-[500] capitalize">{item?.name}</h2>
                </div>
                <p><FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} size="lg" className={clsx("text-blue", expanded && "text-black")} /></p>
            </div>

            {expanded && <div className="w-full mt-2 p-4">
                <div className="w-full">
                    <div className='grid grid-cols-2 gap-6'>
                        <div>
                            <label className='text-[gray] text-sm font-[400]'>Full Name</label>
                            <input placeholder="User Name" onChange={(e) => setValues((prev) => ({ ...prev, name: e.target.value }))} defaultValue={item?.name} className="mt-1 h-[45px] w-full border-[1px] border-gray-200 rounded-md outline-none px-4 text-lg font-[400] capitalize" />
                        </div>
                        <div>
                            <label className='text-[gray] text-sm font-[400]'>Email</label>
                            <input placeholder="Email" onChange={(e) => setValues((prev) => ({ ...prev, email: e.target.value }))} defaultValue={item?.email} className="mt-1 h-[45px] w-full border-[1px] border-gray-200 rounded-md outline-none px-4 text-md font-[400] " />
                        </div>
                    </div>

                    <div className='w-full flex items-center justify-between mt-6'>
                        <Button isLoading={loading} disabled={loading} onClick={UpdateUser}>Update</Button>
                        <div className='w-[20%] flex items-center justify-between'>
                            {/* <label className='w-full text-center'><FontAwesomeIcon icon={faBan} className='text-xl cursor-pointer text-[black]' /></label> */}
                            <label className='w-full text-center'><FontAwesomeIcon icon={faTrash} className='text-xl cursor-pointer text-[red]' onClick={ToggleDeletePopup} /></label>
                        </div>
                    </div>


                </div>
            </div>}


            {/* {blockPopup ? <SuretyModel type="Block" toggleModel={ToggleBlockPopup} /> : null} */}
            {deletePopup ? <SuretyModel toggleModel={ToggleDeletePopup} onClick={DeleteUser} isLoading={loading} /> : null}
        </div>
    )
}

export default SingleUser
