import { useState } from "react";
import axiosInstance from "../../utils/axios-instance";
import Button from "../button/Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UseAuth from "../../utils/use-auth";
import clsx from "clsx";

const PaymentSettings = () => {
  const navigate = useNavigate();
  const { user } = UseAuth();
  const [loading, setLoading] = useState(false);

  const CancelSubscription = async () => {
    setLoading(true);
    try {
      await axiosInstance.post("/payment/stripe/cancel-subscription");
      navigate("/pricing?message=Subscription Cancelled Successfully");
      toast.success("Subscription Cancelled Successfully");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="w-[50%] mx-auto">
      <div className="w-full py-10">
        {user?.subscription ? (
          <div className="w-full p-6 border-[2px] border-gray-200 rounded-md">
            <div className="w-full flex items-center justify-between">
              <div>
                <p className="text-[gray] m-0 capitalize">
                  Current Plan ( {user?.subscription?.plan} ){" "}
                  <span
                    className={clsx(
                      "text-md font-[400] text-red-600",
                      user?.subscription?.status === "paid" && "!text-green-500"
                    )}
                  >
                    ( {user?.subscription?.status} )
                  </span>
                </p>
                <h2 className="text-3xl text-blue font-[600] mt-[-10px] capitalize">
                  {user?.subscription?.type}
                </h2>
              </div>
              <div>
                <h2 className="text-2xl text-blue font-[500] ">
                  {user?.subscription?.totalUsers}+ User
                </h2>
              </div>
            </div>
            <Button
              isLoading={loading}
              disabled={loading}
              className="!mt-8 !bg-[red]"
              onClick={CancelSubscription}
            >
              {user?.subscription?.status === "paid"
                ? "Cancel Subscription"
                : "Unsubscribe"}
            </Button>
          </div>
        ) : (
          <div className="w-full p-6 border-[2px] border-gray-200 rounded-md">
            <h2 className="text-3xl text-blue font-[600] mt-[-10px] capitalize">
              No Subscription
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentSettings;
