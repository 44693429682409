import HomeCrousalCard from "../cards/Home-crousal-card.tsx";
import { content } from "../../data.tsx";
import "./carousel.css"; // Adjust path as necessary
import { useRef } from "react";

const HomeCrousal = () => {
  const carousel = useRef<HTMLDivElement>();

  const GoNext = () => {
    if (carousel.current) {
      carousel.current.scrollBy({
        left: carousel.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const GoPrev = () => {
    if (carousel.current) {
      carousel.current.scrollBy({
        left: -carousel.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="relative">
      <div className="w-[98%] mx-auto carousel-container" ref={carousel}>
        <div className="carousel-inner py-4">
          {content.crasual.map((item, index) => (
            <div key={index} className="w-[40%] flex carousel-item shadow-md">
              <HomeCrousalCard heading={item.head} imgurl2={item.imgUrl2} />
            </div>
          ))}
        </div>
      </div>

      <div className="w-[100%] flex items-center justify-between absolute top-[50%] translate-y-[-50%]">
        <button
          className="h-10 w-10 bg-white flex items-center justify-center rounded-full shadow-lg carousel-button prev"
          onClick={GoPrev}
        >
          &#10094;
        </button>
        <button
          className="h-10 w-10 bg-white flex items-center justify-center rounded-full shadow-lg carousel-button next"
          onClick={GoNext}
        >
          &#10095;
        </button>
      </div>
    </div>
  );
};

export default HomeCrousal;
