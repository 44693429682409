import React, { useState } from 'react';
import Button from '../components/button/Button.tsx';
import StyledMdText from '../common/components/styledMdText/StyledMdText.tsx';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axios-instance.ts';
import { toast } from 'react-toastify';
import { ParseErrors } from '../utils/parse-errors.ts';

const Login = () => {
  const [values, setValues] = useState<Record<string, any>>({})
  const NavigateTo = useNavigate()


  const HandleLogin = async () => {
    if (validate()) {
      try {
        const response = await axiosInstance.post('/auth/login', values)
        NavigateTo("/chats")
        toast.success(response.data.message)
      } catch (error) {
        console.log(error)
        toast.error(ParseErrors(error))
      }
    }
  }


  const HandleChangeValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }


  const validate = () => {
    if (!values.email) {
      toast.error("Please enter email address")
      return false
    } else if (!values.password) {
      toast.error("Please enter password")
      return false
    } else {
      return true
    }
  }

  return (
    <div className='w-[full] bg-white flex justify-center items-center'>

      <div className=" md:w-[60%] lg:w-[50%] flex flex-col items-center justify-center py-[20%] lg:py-0 p-4">
        <div className=" bg-white p-8 rounded-lg  w-full lg:max-w-md">
          <h2 className="text-2xl font-bold text-center text-blue mb-6">Sign In</h2>
          <div className="space-y-3">
            <StyledMdText children={"Email"} fontColor={'text-black'} />
            <input
              type="email"
              name='email'
              placeholder="Please enter your email"
              className="w-full p-3 rounded-lg border bg-[#F1F1F1] border-gray-300"
              value={values?.email}
              onChange={HandleChangeValues}
            />
            <StyledMdText children={"Password"} fontColor={'text-black'} />
            <input
              type="password"
              name="password"
              placeholder="Please enter your password"
              className="w-full p-3 rounded-lg border bg-[#F1F1F1] border-gray-300"
              value={values?.password}
              onChange={HandleChangeValues}
            />
            <div className="flex items-center space-x-2">

              <label htmlFor="agreeTerms" className="text-sm text-blue cursor-pointer" onClick={() => { NavigateTo("/forgot") }}>Forgot your password? Reset it here</label>
            </div>
            <Button children={"LogIn"} onClick={HandleLogin} />
          </div>
          <div className="text-center mt-4">
            <p  className="text-sm text-gray-600">Don’t have account yet? Register</p>
            <Button children={"Sign Up"} onClick={() => { NavigateTo("/signup") }} />
          </div>
        </div>
      </div>
      <img className='lg:flex hidden lg:w-[50%] h-screen' src='/assets/images/login.png' alt='Signup' />

    </div>

  );
};

export default Login;
