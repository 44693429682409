import { Link, NavLink } from "react-router-dom";
import StyledMdText from "../common/components/styledMdText/StyledMdText.tsx";
import Button from "../components/button/Button.tsx";
import MobileHeader from "./Mobile-header.tsx";
import { content } from "../data.tsx";
import UseAuth from "../utils/use-auth.ts";
import UseChat from "../utils/use-chat.ts";

const Header = ({ className }: { className?: string }) => {
  const { setCurrentConversation } = UseChat();
  const { user, HandleUserLogOut } = UseAuth();

  return (
    <div
      className={`sticky bg-white top-0 z-50 w-full max-w-[1440px] mx-auto border-b-[1px] border-b-gray-200 ${className}`}
    >
      <div className="flex justify-center lg:w-[95%] w-[95%] py-3 mx-auto h-[75px]">
        <div className="w-[100%] flex items-center justify-between">
          <div className="flex items-center justify-start">
            <div className="flex justify-start mr-8">
              <Link to="/">
                <img
                  className="w-[80px]"
                  src="/assets/images/coachai.png"
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="justify-center hidden gap-6 lg:flex">
              {content.menuItems.map((item, index) => (
                <NavLink to={item.url} key={index}>
                  <StyledMdText
                    children={item.head}
                    fontColor={
                      "text-lightblack hover:text-blue  font-bold  px-4 py-2"
                    }
                  />
                </NavLink>
              ))}
            </div>
          </div>
          <div className="flex justify-end items-center gap-4">
            {user?.id ? (
              <div className="hidden lg:flex">
                <NavLink to={"/chats"} onClick={() => setCurrentConversation(null)}>
                  <Button
                    children="Start Chat"
                    variant="secondary"
                    disabled={false}
                  />
                </NavLink>

                <NavLink to={"/user-setting"}>
                  <Button
                    children="Setting"
                    variant="secondary"
                    disabled={false}
                  />
                </NavLink>
                <Button
                  children="Logout"
                  variant="primary"
                  disabled={false}
                  onClick={HandleUserLogOut}
                />
                <NavLink to={"/user-setting"}>
                  <div className="h-[45px] w-[45px] rounded-full flex items-center justify-center bg-blue ml-4">
                    <h2 className="uppercase text-white font-[500]">
                      {user.name.slice(0, 2)}
                    </h2>
                  </div>
                </NavLink>
              </div>
            ) : (
              <div className=" hidden lg:flex">
                <NavLink to={"/login"}>
                  <Button children="Login" variant="secondary" />
                </NavLink>
                <NavLink to={"/signup"}>
                  <Button children="Signup" />
                </NavLink>
              </div>
            )}
            <div className="flex items-center justify-center lg:hidden">
              <MobileHeader activeSection={undefined} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
