import { useEffect, useState } from "react";
import PricingCard from "../components/cards/Pricing-card.tsx";
import StyledH2Heading from "../common/components/styledH2Heading/StyledH2Heading.tsx";
import CustonHeding from "../common/components/customHeading/CustonHeding.tsx";
import { content } from "../data.tsx";
import Header from "../layout/Header.tsx";
import { useSearchParams } from "react-router-dom";
import Footer from "../layout/Footer.tsx";
import SubscriptionInfoModel from "../models/subscription-info-model.tsx";

const Pricing = () => {
  const [activeCardIndex] = useState(null);
  const [isMonthly, setIsMonthly] = useState(true);
  const [params, setParams] = useSearchParams();
  const [subModel, setSubModel] = useState<"unpaid" | "no-subscription" | null>(
    null
  );

  useEffect(() => {
    const subid = params.get("id");
    const subtype = params.get("subtype");
    if (subid || subtype) {
      subid !== "undefined" && subtype !== "undefined"
        ? setSubModel(subtype as any)
        : setSubModel("no-subscription");
      params.delete("id");
      params.delete("subtype");
      setParams(params);
    }
  }, []);

  return (
    <>
      <Header className={"bg-white"} />
      <div className="w-full py-[8%] bg-white  gap-5 flex flex-col justify-center items-center max-w-[1440px] mx-auto">
        <StyledH2Heading
          regularContent={"Choose Your Plan "}
          regularFontColor={"text-blue"}
        />
        <div className="flex w-[90%] md:w-[60%] lg:w-[34%] bg-[#C8E3FF] border border-black rounded-md p-[6px] ">
          <button
            className={`items-center justify-center self-stretch flex w-1/2 ${
              isMonthly
                ? "bg-white py-4 text-blue text-bold rounded-sm"
                : "bg-[#C8E3FF] text-blue"
            }`}
            onClick={() => setIsMonthly(true)}
          >
            Monthly
          </button>
          <button
            className={`items-center justify-center flex self-stretch w-1/2 ${
              !isMonthly
                ? "bg-white py-4 text-blue  text-bold rounded-sm"
                : "bg-[#C8E3FF] text-blue"
            }`}
            onClick={() => setIsMonthly(false)}
          >
            Yearly (Save 25%)
          </button>
        </div>
        <CustonHeding
          fontColor={"text-black !font-normal md:text-2xl"}
          content={"Best Plans For "}
          customColor={"text-blue md:text-2xl"}
          diffText={"You"}
          content2={undefined}
        />

        <div className="w-[90%] mt-10  flex lg:flex-row flex-col items-start  justify-center gap-6 ">
          {content.pricingPlans.map((plan, index) => (
            <PricingCard
              key={index}
              ind={index}
              title={plan.title}
              subtitle={plan.subtitle}
              type={plan.type}
              features={plan.features}
              isActive={index === activeCardIndex}
              isMonthly={isMonthly}
            />
          ))}
        </div>
      </div>
      <Footer />

      {subModel !== null ? (
        <SubscriptionInfoModel
          type={subModel}
          toggleModel={() => setSubModel(null)}
        />
      ) : null}
    </>
  );
};

export default Pricing;
