import React from 'react'
import Button from '../components/button/Button'


interface ISuretyModel {
    type?: "Delete" | "Block",
    isLoading?: boolean,
    onClick: () => void,
    toggleModel: () => void
}

const SuretyModel = ({ type = "Delete", isLoading, onClick, toggleModel }: ISuretyModel) => {
    return (
        <div className='w-full h-full fixed top-0 bg-[rgba(0,0,0,0.5)] left-0 z-50 flex items-center justify-center'>
            <div className='p-6  rounded-md bg-white shadow-sm min-w-[500px]'>
                <h2 className="text-2xl font-[500]">Are you sure you want to {type} ?</h2>

                <div className='mt-8 flex items-center justify-between'>
                    <Button className='w-auto bg-black' onClick={toggleModel} isLoading={isLoading} disabled={isLoading}>Cancel</Button>
                    <Button className='w-auto bg-red-500' onClick={onClick} isLoading={isLoading} disabled={isLoading}>{type}</Button>
                </div>
            </div>
        </div>
    )
}

export default SuretyModel