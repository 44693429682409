import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React from 'react'


interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  variant?: "primary" | "secondary";
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
}

const Button: React.FC<IButton> = ({ children, variant = "primary", onClick, disabled, className, isLoading }) => {

  const primaryVariant = variant === "primary" ? "bg-blue text-white" : ""

  return (
    <button
      disabled={disabled}
      className={clsx(primaryVariant, "!px-[22px] text-nowrap !font-[500] w-[100%] hover:scale-[0.99] hover:duration-200 text-sm md:text-md p-[9px] md:p-[12px] rounded-md md:font-semibold", className, disabled && "opacity-70 cursor-not-allowed")}
      onClick={onClick}
    >
      {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : children}
    </button>
  )
}

export default Button