import React from "react";
import StyledH4Heading from "../common/components/styledH3Heading/styledH3Heading.tsx";
import StyledLgText from "../common/components/styledLgText/StyledLgText.tsx";
import Button from "../components/button/Button.tsx";
import Header from "../layout/Header.tsx";
import { NavLink } from "react-router-dom";
import Footer from "../layout/Footer.tsx";

const Page404 = () => {
  return (
    <>
      <div className="max-w-[1440px] mx-auto">
        <Header className={"bg-white"} />
        <div className="bg-white items-center justify-center w-full flex ">
          <div className="w-[90%] text-center items-center justify-center py-[35%] lg:py-[5%] flex flex-col ">
            <img
              className="w-[70%] lg:w-[50%]"
              src="/assets/images/404page.png"
              alt="404 page"
            />
            <StyledH4Heading
              children={"Something went wrong."}
              fontColor={"text-black"}
            />
            <StyledLgText
              children={"Sorry, We can’t find the page you’re looking for."}
              fontColor={"text-black"}
            />
            <div className="lg:w-[10%] pt-5">
              <NavLink to={"/"}>
                <Button children={"Go Back"} disabled={false} />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Page404;
