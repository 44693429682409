import clsx from 'clsx';
import React from 'react'

interface IStyledH1Heading {
  fontColor: string;
  content: string;
  customColor: string;
  diffText: string;
  className?: string;
}

const StyledH1Heading: React.FC<IStyledH1Heading> = ({ fontColor, content, customColor, diffText, className }) => {
  return (
    <h1 className={clsx(`${fontColor} font-bold text-2xl md:text-3xl lg:text-6xl leading-snug md:leading-tight`, className)}>
      {content} {" "}
      <span className={`${customColor} `}>
        {diffText}
      </span>

    </h1>
  );
};

export default StyledH1Heading