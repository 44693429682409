import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axiosInstance from '../utils/axios-instance';
import moment from 'moment';
import UseChat from '../utils/use-chat';
import UseAuth from '../utils/use-auth';
import Button from '../components/button/Button';
import RemoveHtmlTags from '../utils/remove-html-tags';



const ChatList = () => {
  const { user } = UseAuth()
  const { setCurrentConversation, setConversations, conversations } = UseChat()
  const [isChatListVisible, setIsChatListVisible] = useState(false);
  const [loading, setLoading] = useState(false)
  const [limit,] = useState(10)


  // ------------------------- [ FETCH CONVERSATIONS ] ---------------------------------

  useEffect(() => {
    const findConversations = async () => {
      try {
        const res = await axiosInstance.get(`/chat/conversations?limit=${limit}`)
        setConversations(res.data)
      } catch (error) {
        console.log(error)
      }
    }

    user?.id && findConversations()
  }, [user?.id])



  const AddNewConversations = async () => {
    setLoading(true)
    try {
      const res = await axiosInstance.get(`/chat/conversations?limit=${limit}&offset=${conversations.data.length}`)
      setConversations({ totalCount: res.data.totalCount, data: [...conversations.data, ...res.data.data] })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }


  return (
    <div className="flex flex-col md:w-[25%] h-full bg-[#FAFAFA]">
      <button
        className={`flex flex-row bg-white md:hidden  ${isChatListVisible ? 'p-0 hidden' : ' p-2'} `}
        onClick={() => setIsChatListVisible(!isChatListVisible)}
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
        </svg>
      </button>

      {/* Chat list */}
      <div className={`flex flex-col h-[100%] max-h-[92%] md:flex ${isChatListVisible ? 'block' : 'hidden'} w-[100%] border-r border-gray-200 pt-4`}>
        <div className='px-4'>
          <div className='w-full flex items-center justify-between mb-4'>
            <h2 className="text-xl font-semibold ">Chats History</h2>

            <Button className='w-auto text-sm' onClick={() => setCurrentConversation(null)}>New Chat</Button>
          </div>
          <div className="relative mb-4">
            <input
              type="text"
              placeholder="Search chat"
              className="w-full p-2 border border-gray-300 rounded-full pl-10 focus:outline-none"
            />
            <span className="absolute left-3 top-3 text-gray-400">
              <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M12.9 14.32a8 8 0 111.41-1.41l4.28 4.29a1 1 0 01-1.42 1.42l-4.28-4.29zM8 14a6 6 0 100-12 6 6 0 000 12z" clipRule="evenodd" />
              </svg>
            </span>
          </div>
        </div>
        <div className='overflow-y-auto h-full custom-scrollbar'>
          {conversations?.data?.map((conversation) => (
            <div
              key={conversation?.id}
              className="w-full flex justify-between items-center py-4 px-8 cursor-pointer hover:bg-gray-100"
              onClick={() => {
                setIsChatListVisible(false);
                setCurrentConversation(conversation)
              }}
            >
              <div className='w-[80%]'>
                <p className="w-full text-ellipsis overflow-hidden text-nowrap font-semibold">{RemoveHtmlTags(conversation?.name.replaceAll('"', ""))}</p>
                <p className="w-full text-ellipsis overflow-hidden text-nowrap text-sm text-gray-600">{RemoveHtmlTags(conversation?.messages[0]?.message)}</p>
              </div>
              <span className="text-xs text-gray-800">{moment(conversation?.createdAt).format("hh:mm")}</span>
            </div>
          ))}

          {conversations?.data.length < conversations?.totalCount ? <Button disabled={loading} isLoading={loading} className='!mt-2' onClick={AddNewConversations}>Load More</Button> : null}
        </div>

      </div>
      <div className="flex bg-[#FAFAFA] w-[100%] py-4 px-4 cursor-pointer  bottom-5 flex-col-reverse md:flex-row justify-between items-center">
        <div className='flex'>
          <img
            src="/assets/icons/Ellipse 37.png"
            alt="Profile"
            className="w-10 h-10 rounded-full mr-3"
          />
          <div>
            <p className="font-bold">{user?.name}</p>
            <p className="text-sm text-gray-500">@{user?.email.split('@')[0]}</p>
          </div>
        </div>
        <NavLink to={'/user-setting'}>
          <span className="ml-auto flex text-xl text-gray-500 cursor-pointer">•••</span>
        </NavLink>
      </div>
    </div>
  );
};

export default ChatList;
