import React from 'react'

const HomeVideoSection = () => {
  return (
    <div className='flex items-center justify-center w-full bg-blue h-[45vh] lg:h-[55vh] lg:mt-[400px] lg:relative'>
        <img className=' lg:absolute w-[60%] lg:w-[70%] left-48 -top-64' src='/assets/images/video.png' alt='video' />
    </div>
  )
}

export default HomeVideoSection