import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home.tsx";
import Page404 from "./pages/Page_404.tsx";
import Pricing from "./pages/Pricing.tsx";
import SignUp from "./pages/Sign-up.tsx";
import Login from "./pages/Login.tsx";
import UserSettings from "./pages/User-setting.tsx";
import Chat from "./pages/Chat.tsx";
import ForgotPassword from "./pages/Forgot-Password.tsx";
import ResetPassword from "./pages/Reset-Password.tsx";
import Contact from "./pages/contact.tsx";
// import Demo from "./pages/demo.tsx";

function App() {
  return (
    <div className="w-full   relative">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/user-setting" element={<UserSettings />} />
        <Route path="/chats" element={<Chat />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/demo" element={<Demo />} /> */}
        <Route path="*" element={<Page404 />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/reset/:id" element={<ResetPassword />} />
      </Routes>
    </div>
  );
}

export default App;
