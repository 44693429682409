import React from 'react';
import { usePagination } from './use-pagination';
import './pagination.css';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';


interface IPaginationMenu {
    onPageChange: (pageSize: number) => void;
    totalCount: number;
    siblingCount?: number;
    currentPage: number;
    pageSize: number;
    className?: string;
}


const PaginationMenu: React.FC<IPaginationMenu> = ({ onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className }) => {

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <ul
            className={clsx('pagination-container w-full justify-center py-5')}
        >

            <li
                className={clsx('pagination-item', {
                    disabled: currentPage === 1
                })}
                onClick={onPrevious}
            >
                <div className="arrow" >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </div>
            </li>
            {paginationRange.map((pageNumber, index) => {
                if (pageNumber === "DOTS") {
                    return <li className="pagination-item dots">&#8230;</li>;
                }
                return (
                    <li
                        className={clsx('pagination-item', {
                            selected: pageNumber === currentPage
                        })}
                        key={index}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        {pageNumber}
                    </li>
                );
            })}
            <li
                className={clsx('pagination-item', {
                    disabled: currentPage === lastPage
                })}
                onClick={onNext}
            >
                <div className="arrow" >
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
            </li>
        </ul>
    );
};

export default PaginationMenu;